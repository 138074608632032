import { createApp } from 'vue'
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'

const app = createApp(App)

app.mount('#app')
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})
