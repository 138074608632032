<template>
  <div class="hello">
    <h1>正光园林苗圃</h1>
    <h2>订单计算器</h2>
    <div class="form">
      <div>
        <strong>数量：</strong>
        <input type="number" v-model="amount">
        <strong>棵</strong>
      </div>
      <br>
      <div>
        <strong>价格：</strong>
        <input type="number" v-model="price">
        <strong>元</strong>
      </div>
      <br>
      <div>
        <strong>总价：{{Math.round(amount*price)}} 元</strong>
      </div>
      <br>
      <div>
        <strong>品种：</strong>
        <select v-model="kind">
          <option disabled value="">请选择品种</option>
          <option>金森女贞</option>
          <option>红叶石楠</option>
          <option>金边黄杨</option>
          <option>海桐</option>
        </select>
      </div>
      <br>
      <div>
        <strong>杯型：</strong>
        <select v-model="cup">
          <option disabled value="">请选择杯型</option>
          <option>大杯</option>
          <option>小杯</option>
          <option>地插</option>
        </select>
      </div>
    </div>
    <br>
    <hr>
    <p>{{result()}}</p>
    <button type="button" v-on:click='copyData'>复制到粘贴板</button>
  </div>
</template>

<script>
import axios from 'axios'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      fingerprint: 'unknown',
      amount: '',
      price: '',
      kind: '',
      cup: '',
    }
  },
  methods: {
    copyData: function (){
      this.recordData()
      console.log("sent request")
      this.$copyText(this.result())
      alert("已经复制到粘贴板，可发送到微信")
      if (window.WeixinJSBridge) {
        window.WeixinJSBridge.call("closeWindow")
      }
      window.close()
    },
    result: function () {
      return '【正光园林苗圃】老板您好，您订购了 '+this.amount+' 棵'+this.kind+this.cup+'苗，'+this.price+' 元一棵，总价 '+Math.round(this.amount*this.price)+' 元。请确认一下订单，谢谢！'
    },
    async recordData() {
      await axios.post('https://dtic0uuv.lc-cn-n1-shared.com/1.1/classes/order', {
        fingerprint: this.fingerprint,
        amount: this.amount,
        price: this.price,
        income: Math.round(this.amount*this.price),
        kind: this.kind,
        cup: this.cup,
      }, {
        headers: {
          "X-LC-Id": "DTIC0uUVkLBbNciqCrOu5K3u-gzGzoHsz",
          "X-LC-Key": "iLz68U0WehxXnpk8c6yWpUGd",
        },
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    },
  },
  mounted: function () {
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        this.fingerprint = result.visitorId
        console.log("fingerprint:", this.fingerprint)
      })
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form {
  font-size: 1.5em;
}

input {
  font-size: 1.25em;
  width: 5em;
}

select {
  font-size: 1.25em;
  width: 6em;
}

button {
  font-size: 2em;
}

</style>
